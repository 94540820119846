
.blog-banner {
    padding-top: 60px;
    padding-bottom: 60px;
}

.blog-banner .feature-badge {
    background: #E9F4FF;
    border-radius: 5px;
    padding: 5px 11px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
    width: fit-content;
}

.blog-banner h1 {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 14px;
}

.blog-banner .blog-date {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #9E9E9E;
    margin-bottom: 11px;
}

.blog-banner p {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: var(--text-paragraph);
    margin-bottom: 40px;
    width: 95%;
    max-width: 478px;
}

.blog-banner .btn-main {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 12px 37px;
}

.postbanner {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    /* padding-top: 100px;
    padding-bottom: 100px; */
    background: #F9F9F9;
    overflow: hidden;
}

.postbanner img {
    width: 100%;
    /* margin: auto; */
    height: auto;
}

.blog-post {
    padding-top: 28px;
    padding-bottom: 70px;
}

.blog-post .post-row {
    border-right: 1px solid #DDDDDD;
}

.postcard {
    margin-bottom: 30px;
}

.postcard .post-img {
    background: #F9F9F9;
    border-radius: 15px;
    width: 100%;
    height: 250px;
    margin-bottom: 28px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: hidden;
}

.postcard .post-img img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.postcard .post-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 17px;
    color: #0071C1;
    text-decoration: none;
}

.postcard .postdate {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #9E9E9E;
    margin-bottom: 17px;
}

.postcard .btn-main {
    padding: 8px 14px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 15px;
    display: block;
    width: fit-content;
}

.categories-search {
    padding-left: 10px;
}

.categories-search h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
}

.categories-search .inputsearch .form-control {
    background: #F2F2F2;
    border-radius: 10px;
    padding: 5px 11px;
    height: 35px;
    line-height: 35px;
    font-weight: 500;
    font-size: 14px;
    border: 0;
    box-shadow: none;
}

.categories-search .inputsearch .input-group-text {
    background-color: #F2F2F2;
    border: none;
    color: #5E6974;
    padding-right: 0;
}

.categories-search .inputsearch .form-control::placeholder {
    color: #5E6974;
}

.pagination-btn {
    padding-top: 60px;
    padding-bottom: 30px;
    position: relative;
}

.pagination-btn ul {
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
}

.pagination-btn ul li a {
    /* width: 15px;
    height: 15px;
    border-radius: 50%;
    opacity: 0.5;
    border: 2px solid #0070C0; */
    margin-right: 6px;
    margin-left: 6px;
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #9E9E9E;
    text-decoration: none;
}

.pagination-btn ul li a:hover,
.pagination-btn ul li a.active {
    /* opacity: 1;
    background: #0070C0;
    border: 1px solid #0070C0;   */
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #0070C0;
}

.pagination-btn ul li button.nextprev {
    border: 0;
    background-color: transparent;
    display: inline-block;
    position: relative;
    top: -3px;
}

.pagination-btn ul li button.nextprev.prev {
    margin-right: 15px;
}

.pagination-btn ul li button.nextprev.next {
    margin-left: 15px;
}

.pagination-btn ul li button.nextprev img {
    width: 18px;
    height: auto;
}

.blog-post-details {
    padding-top: 66px;
    padding-bottom: 100px;
    background-image: url('../../images/backgrounds/bg-top.svg'),url('../../images/backgrounds/bg-bottom.svg');
    background-position: top left, top 200px right;
    background-repeat: no-repeat;
}

.blog-post-details .postbanner {
    margin-bottom: 20px;
}

.blog-post-details .postbanner img {
    width: 100%;
    height: auto;
}


.blog-post-details .blog-banner h1 {
    margin-bottom: 18px;
}

.blog-post-details .blog-banner .blog-date {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 30px;
}

.blog-post-details p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: var(--text-paragraph);
    margin-bottom: 25px;
}

.blog-post-details p span {
    color: var(--text-main);
    font-weight: 600;
}

.share-list {
    padding-left: 10px;
    padding-top: 35px;
}

.share-list h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
}

.share-list ul li a {
    background: #FFFFFF;
    border: 1px solid #F5F4F7;
    border-radius: 5px;
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.media-post-row {
    padding: 30px 0px;
    border-bottom: 1px solid #DDDDDD;
}

.media-post-row h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    color: #0071C1;
}

.media-post-row p {
    margin-bottom: 44px;
}

.media-post-row .post-date {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #9E9E9E;
    margin-bottom: 17px;
}

.media-post-row .btn-main {
    padding: 8px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}

.media-post-row .media-img {
    width: 100%;
    height: 100%;
    background-color: #F9F9F9;
    border-radius: 10px;
    overflow: hidden;
    /* display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px; */
}

.media-post-row .media-img img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.media_video {
    border-radius: 15px;
    overflow: hidden;
    margin-top: 30px;
}

.nofound-img {
    padding-top: 55px;
    padding-bottom: 70px;
    border-right: 1px solid #DDDDDD;
}

.nofound-img img {
    width: 100%;
    max-width: 324px;
    height: auto;
    margin-bottom: 30px;
}

.nofound-heading {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
}

@media (max-width: 992px) {
    .postcard .post-img {
        height: 180px;
    }
}

@media (max-width: 992px) {

    .blog-banner h1 {
        font-size: 28px;
        line-height: 35px;
    }

    .blog-banner p {
        font-size: 16px;
    }

    .postcard .post-img {
        height: 200px;
    }

    .postcard .post-title {
        font-size: 18px;
    }

    .categories-search h2 {
        font-size: 20px;
    }

    .media-post-row h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .media-post-row p {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .nofound-img {
        padding-top: 35px;
        padding-bottom: 50px;
    }

    .nofound-heading {
        font-size: 35px;
        line-height: 45px;
    }
}

@media (max-width: 767px) {

    .blog-banner {
        padding-top: 30px;
        padding-bottom: 10px;
    }

    .blog-banner h1 {
        font-size: 25px;
        line-height: 35px;
    }

    .blog-post {
        padding-top: 15px;
        padding-bottom: 30px;
    }

    .blog-post .post-row {
        border-right: 0px;
    }

    .postcard .post-img {
        height: 250px;
    }

    .blog-post-details {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .postbanner {
        /* padding-top: 30px;
        padding-bottom: 30px; */
        margin-bottom: 20px;
    }

    .blog-post-details .postbanner img {
        max-height: 150px;
        object-fit: cover;
    }

    .blog-post-details p {
        font-size: 14px;
    }

    .categories-search h2 {
        font-size: 18px;
    }

    .share-list {
        padding-top: 15px;
    }

    .media-post-row {
        padding: 15px 0px;
    }

    .media-post-row .btn-main {
        margin-bottom: 30px;
    }

    .pagination-btn {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .nofound-img {
        padding-top: 25px;
        padding-bottom: 40px;
        border-right: none;
    }

    .nofound-heading {
        font-size: 25px;
        line-height: 40px;
    }
}

@media (max-width: 400px) {
    .nofound-img img {
        max-width: 250px;
    }
}