

.main-banner {
    padding-top: 90px;
    padding-bottom: 90px;
    background-image: url('../../images/backgrounds/bg-top.svg'), url('../../images/backgrounds/bg-bottom.svg');
    background-position: top left, bottom right;
    background-repeat: no-repeat;
}

.main-banner h1 {
    margin-bottom: 25px;
}

.main-banner p {
    margin-bottom: 30px;
    line-height: 32px;
}

.main-banner video {
    height: auto;
    width: 100%;
}

@media (max-width: 992px) {
    .main-banner video {
        height: auto !important;
        width: 100%;
    }
}

@media (max-width: 767px) {

    .main-banner h1 {
        max-width: 90%;
        margin: auto;
        margin-bottom: 25px;
    }

    .main-banner .col-md-5.text-end {
        text-align: center !important;
    }

    .main-banner {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@media (max-width: 585px) {

    .main-banner h1 {
        max-width: 95%;
    }

    .main-banner p {
        line-height: 17px;
    }
}