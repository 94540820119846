.main-banner.about-banner {
    padding-top: 69px;
    padding-bottom: 69px;
}

.main-banner.about-banner h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 26px;
}

.main-banner.about-banner p {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
}

.main-banner.about-banner p:last-child {
    margin-bottom: 0px;
}

.ourstory_timeline {
    padding-top: 65px;
    padding-bottom: 65px;
}

.ourstory_timeline .timeline-container::after {
    content: '';
    position: absolute;
    top: 3px;
    bottom: 0;
    left: 50%;
    width: 1px;
    height: 90%;
    background-color: var(--bg-primary);
    margin-left: -3px;
}

.ourstory_timeline .timeline-container .timeline-content {
    padding: 0px 25px 46px 25px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

.ourstory_timeline .timeline-container .timeline-content::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    right: -8px;
    background-color: #fff;
    background-image: url('../../images/about/arrow-right.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    top: 2px;
    z-index: 1;
}

.ourstory_timeline .timeline-container .timeline-content.left {
    left: 0;
    text-align: right;
}

.ourstory_timeline .timeline-container .timeline-content.left::after {
    background-image: url('../../images/about/arrow-left.svg');
}

.ourstory_timeline .timeline-container .timeline-content.right {
    left: 50%;
    text-align: left;
}

.ourstory_timeline .timeline-container .timeline-content.right::after {
    left: -13px;
}

.ourstory_timeline .timeline-container .timeline-content h2 {
    color: var(--section-inner-heading);
    margin-bottom: 14px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.ourstory_timeline .timeline-container .timeline-content p {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 17px;
}

.Leadership {
    padding-top: 35px;
    padding-bottom: 50px;
}

.Leadership h2,
.our-company h2 {
    margin-bottom: 60px;
}

.Leadership .leadership-card {
    cursor: pointer;
}

.Leadership .leadership-card .member-image {
    /* background: linear-gradient(180deg, #F6F9FF 0%, rgba(244, 244, 244, 0) 100%); */
    border-radius: 10px;
    padding: 35px 52px 0px 52px;
    text-align: center;
    margin-bottom: 35px;
    background-image: url('../../images/about/img-card-bg.png'), url('../../images/about/angle-top.svg'), url('../../images/about/angle-down.svg');
    background-position: center, top left, bottom right;
    background-size: cover, auto, auto;
    background-repeat: no-repeat;
}

.Leadership .leadership-card .member-image img {
    width: auto;
    height: 275px;
}

.Leadership .leadership-card .member-details .member-name {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 7px;
    color: var(--section-inner-heading);
    position: relative;
    width: fit-content;
    padding-right: 25px;
    background-image: url('../../images/about/l-arrow.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 14px;
}

.Leadership .leadership-card .member-details .member-post {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--text-paragraph);
}

.our-company {
    padding-top: 50px;
    padding-bottom: 50px;
}

.our-company .company {
    background: #FBFBFB;
    border-radius: 10px;
    padding: 60px 0px 55px 0px;
    position: relative;
}

.our-company .company::after {
    content: '';
    position: absolute;
    width: 97%;
    height: 100%;
    background-color: #DBEDFF;
    border-radius: 10px;
    top: 16px;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

.our-company .company img.company-world {
    width: 90%;
    max-width: 580px;
    height: auto;
    margin: auto;
    margin-bottom: 40px;
}

.our-company .partner-members {
    padding-top: 40px;
    padding-bottom: 40px;
}

.our-company .partner-members h3 {
    color: var(--section-inner-heading);
    margin-bottom: 60px;
    text-align: center;
    font-size: 24px;
    line-height: 29px;
}

.our-company .partner-members ul li {
    padding: 10px 35px;
    text-align: center;
    display: flex;
    align-items: center;
}

.our-company .partner-members ul li div img {
    width: 100%;
    max-width: 280px;
    height: auto;
    margin-bottom: 20px;
}

.our-company .partner-members ul li div img.color-img {
    display: none;
}

.our-company .partner-members ul li:hover div img.grey-img {
    display: none;
}

.our-company .partner-members ul li:hover div img.color-img {
    display: block;
}

.our-company .partner-members ul.partner-logo li div img {
    width: auto;
    max-height: 90px;
}

.our-achivement {
    padding-top: 70px;
    padding-bottom: 70px;
}

.our-achivement .company.achiements {
    background: #FAFBFF;
}

.media-coverage {
    background: #F9FBFF;
    padding-top: 60px;
    padding-bottom: 90px;
}

.media-coverage h2 {
    margin-bottom: 60px;
}

.media-coverage .slick-slider .slick-arrow {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px;
    width: 20px;
    height: 20px;
    bottom: -19%;
    top: unset;
}

.media-coverage .slick-slider .slick-prev::before,
.media-coverage .slick-slider .slick-next::before {
    display: none;
}

.media-coverage .slick-slider .slick-prev {
    left: 44% !important;
    background-image: url('../../images/testimonials/navprev.svg');
}

.media-coverage .slick-slider .slick-next {
    right: 44% !important;
    background-image: url('../../images/testimonials/navnext.svg');
}

.media-coverage .slick-slide {
    padding: 10px 23px;
}

.media-coverage .slick-dots li {
    width: auto;
    margin: 0px 6px;
    display: none;
}

.media-coverage .slick-dots li:first-child,
.media-coverage .slick-dots li:nth-child(2),
.media-coverage .slick-dots li:nth-child(3) {
    display: inline-block;
}

.media-coverage .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid rgb(0 112 192 / 50%);
    padding: 0;
}

.media-coverage .slick-dots li.slick-active button {
    background-color: #0070C0;
}

.media-coverage .slick-dots li button::before {
    display: none;
}

.media-coverage .Media-partner {
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    margin-left: 20px;
    width: 300px;
}

.media-coverage .slick-slider .slick-dots {
    bottom: -55px !important;
    transform: translateX(-50%);
    left: 50%;
    width: fit-content;
    padding: 0px 20px;
}

.media-coverage .Media-partner .m-body {
    background-color: #FBFBFB;
    border-bottom: 1px solid #DEDEDE;
    padding: 35px;
    text-align: center;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-coverage .Media-partner .m-body img {
    width: auto;
    max-width: 210px;
    max-height: 100px;
    height: auto;
}

.media-coverage .Media-partner .m-footer {
    padding: 25px 30px;
}

.media-coverage .Media-partner .m-footer .f-header {
    color: var(--section-inner-heading);
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.media-coverage .Media-partner .m-footer .f-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #211E1E;
}

.media-coverage .Media-partner .m-footer .f-content img {
    width: 16px;
    height: auto;
    display: inline-block;
}

.team_modal .modal-body {
    padding: 40px 40px;
}

.team_modal .modal-body .user-img {
    border-radius: 10px;
    padding: 35px 35px 0px 35px;
    text-align: center;
    background-image: url('../../images/about/img-card-bg.png'), url('../../images/about/angle-top.svg'), url('../../images/about/angle-down.svg');
    background-position: center, top left, bottom right;
    background-size: cover, auto, auto;
    background-repeat: no-repeat;
    height: 100%;
    position: relative;
}

.team_modal .modal-body .user-img img {
    width: auto;
    height: 80%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translate(-50%);
}

.team_modal .modal-body .user-name {
    color: var(--section-inner-heading);
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
}

.team_modal .modal-body .user-posiiton {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--text-paragraph);
    margin-bottom: 25px;
}

.team_modal .modal-body .user-details {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: var(--text-paragraph);
    margin-top: 30px;
    margin-bottom: 25px;
}

.team_modal .modal-body .user_scial_links img {
    width: auto;
    height: auto;
    margin-right: 10px;
}

.team_modal .modal-dialog-centered {
    /* align-items: flex-end !important; */
    /* bottom: 10%; */
}

.main-banner.contactUs-banner {
    padding-top: 104px;
}

@media (min-width: 1600px) {
    .media-coverage .slick-slider .slick-prev {
        left: 46% !important;
    }
    .media-coverage .slick-slider .slick-next {
        right: 46% !important;
    }
}

@media (max-width: 1400px) {
    .media-coverage .slick-slider .slick-prev {
        left: 44% !important;
    }
    .media-coverage .slick-slider .slick-next {
        right: 44% !important;
    }
}

@media (max-width: 1300px) {
    .Leadership .leadership-card .member-image img {
        height: 250px;
    }
}

@media (max-width: 1200px) and (min-width: 993px) {
    .our-company .partner-members ul li a img {
        max-width: 220px;
    }
    .media-coverage .Media-partner .m-body img {
        max-width: 170px;
        max-height: 80px;
    }
    .Leadership .leadership-card .member-image img {
        height: 200px;
    }
}

@media (max-width: 1100px) {
    .media-coverage .slick-slider .slick-prev {
        left: 42% !important;
    }
    .media-coverage .slick-slider .slick-next {
        right: 42% !important;
    }
}

@media (min-width: 992px) {
    .team_modal .modal-lg {
        --bs-modal-width: 870px !important;
    }
}

@media (max-width: 992px) {
    .Leadership .leadership-card .member-image {
        padding: 15px 25px 0px 25px;
    }
    .Leadership .leadership-card .member-image img {
        height: 160px;
    }
    .Leadership .leadership-card .member-details .member-name {
        font-size: 20px;
    }
    .Leadership .leadership-card .member-details .member-post {
        font-size: 14px;
    }
    .our-company .partner-members ul li a img {
        max-width: 230px;
    }
    .media-coverage .Media-partner .m-body img {
        max-width: 160px;
        max-height: 70px;
    }
    .team_modal .modal-body {
        padding: 20px 20px;
    }
    .team_modal .modal-body .user-img {
        padding: 20px 20px 0px 20px;
        height: auto;
    }
    .team_modal .modal-body .user-img img {
        position: relative;
        transform: none;
        top: auto;
        left: auto;
        width: 100%;
        height: auto;
    }
    .team_modal .modal-body .user-posiiton {
        font-size: 14px;
        line-height: 18px;
    }
    .team_modal .modal-body .user-details {
        font-size: 14px;
        line-height: 24px;
    }
}

@media (max-width: 900px) {
    .media-coverage .slick-slider .slick-prev {
        left: 40% !important;
    }
    .media-coverage .slick-slider .slick-next {
        right: 40% !important;
    }
}

@media (max-width: 800px) {
    .media-coverage .slick-slider .slick-prev {
        left: 38% !important;
    }
    .media-coverage .slick-slider .slick-next {
        right: 38% !important;
    }
}

@media (max-width: 767px) {
    .main-banner.about-banner h1 {
        font-size: 30px;
        line-height: 25px;
        margin-bottom: 16px;
        max-width: 100%;
    }
    .main-banner.about-banner {
        padding-top: 30px;
        padding-bottom: 60px;
    }
    .main-banner.about-banner p {
        font-weight: 500;
        font-size: 14px;
    }
    .ourstory_timeline {
        padding-top: 15px;
        padding-bottom: 20px;
    }
    .ourstory_timeline .timeline-container .timeline-content h2 {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
    }
    .ourstory_timeline .timeline-container .timeline-content p {
        font-size: 12px;
    }
    .Leadership {
        padding-top: 15px;
        padding-bottom: 20px;
    }
    .Leadership h2,
    .our-company h2 {
        margin-bottom: 20px;
    }
    .Leadership .leadership-card {
        margin-bottom: 30px;
    }
    .Leadership .leadership-card .member-details {
        text-align: center;
    }
    .Leadership .leadership-card .member-details .member-name {
        font-size: 20px;
        line-height: 22px;
    }
    .Leadership .leadership-card .member-details .member-post {
        font-size: 14px;
    }
    .Leadership .leadership-card .member-image img {
        width: 100%;
        max-width: 280px;
        height: auto;
    }
    .our-company .partner-members h3,
    .media-coverage h2 {
        margin-bottom: 20px;
    }
    .our-company .partner-members ul li a img {
        max-width: 200px;
        margin-bottom: 15px;
    }
    .our-company .partner-members {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .media-coverage {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .media-coverage .Media-partner {
        margin-right: 0;
        margin-left: 0;
    }
    .media-coverage .Media-partner .m-footer {
        padding: 15px 20px;
    }
    .media-coverage .Media-partner .m-footer .f-header {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .media-coverage .Media-partner .m-footer .f-content {
        font-size: 14px;
        line-height: 18px;
    }
    .team_modal .modal-body .user-img {
        margin-bottom: 20px;
        display: block;
    }
}