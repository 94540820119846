.main-banner.center-top-banner {
    padding-top: 50px;
    padding-bottom: 35px;
    background-image: url('../../images/backgrounds/bg-top.svg'), url('../../images/backgrounds/bg-bottom.svg');
    background-position: top left, bottom right;
    background-repeat: no-repeat;
}

.main-banner h1 {
    margin-bottom: 25px;
}

.main-banner p {
    margin-bottom: 30px;
    line-height: 32px;
}

.inner-banner h1 {
    font-weight: 800;
}

.inner-banner .btn-main {
    padding: 12px 24px;
}

@media (max-width: 767px) {

    .main-banner h1 {
        max-width: 90%;
        margin: auto;
        margin-bottom: 25px;
    }

    .main-banner .col-md-5.text-end {
        text-align: center !important;
    }

    .main-banner {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .inner-banner .col-md-5.text-end img {
        width: 80% !important;
    }
}

@media (max-width: 585px) {

    .main-banner h1 {
        max-width: 95%;
    }

    .main-banner p {
        line-height: 17px;
    }
}